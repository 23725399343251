<template>
  <div class="card p-shadow-6">
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <h1>Minhas Formações</h1>
    <div class="p-col-12"></div>
    <div style="height: 65vh">
      <DataTable
        ref="dt"
        :value="participationList"
        :filters.sync="filters"
        filterDisplay="row"
        :loading="loading"
        :rows="participationList.length"
        class="p-datatable-sm"
        sortField="user.name"
        :sortOrder="2"
        responsiveLayout="scroll"
        rowGroupMode="subheader"
        groupRowsBy="user.name"
        @filter="calculateTotalTraining"
        :exportFilename="`Relatório_Formação_` + year"
        :scrollable="true"
        scrollHeight="flex"
        :autoLayout="true"
      >
        <template #header>
          <div style="text-align: left">
            <div class="table-header">
              <label for="year" class="p-mr-1 p-my-auto">Ano</label>
              <InputNumber
                name="year"
                :value="year"
                class="p-ml-2 p-mr-2"
                showButtons
                buttonLayout="stacked"
                inputStyle="width:65px"
                :step="1"
                :min="currentYear - 10"
                :max="currentYear"
                :useGrouping="false"
                @input="yearChanged"
              />
              <Button
                label="Adicionar Participação em Formação"
                icon="pi pi-plus"
                class="p-button-success p-mr-2"
                @click="newTraining"
              ></Button>
              <Button
                icon="pi pi-external-link"
                label="Exportar Relatório"
                @click="exportCSV($event)"
              />
            </div>
          </div>
        </template>
        <template #empty> Nada a mostrar. </template>
        <template #loading> A carregar. Por favor Aguarde... </template>
        <template #groupheader="slotProps">
          {{ slotProps.data.user.username }}
        </template>
        <Column :exportable="false" field="user.name">
          <template #body="slotProps">
            {{ slotProps.data.user.name }}
          </template>
        </Column>
        <Column
          field="user.username"
          header="Colaborador"
          sortable
          filterField="user.username"
          dataType="boolean"
          v-if="
            loggedUser.isEsa ||
            loggedUser.department == 'Administradores' ||
            loggedUser.id == 128
          "
        >
          <template #body="slotProps">
            {{ slotProps.data.user.username }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="activeUsers"
              :optionLabel="'username'"
              :optionValue="'username'"
              class="p-column-filter"
              :showClear="true"
              :filter="true"
            >
            </Dropdown>
          </template>
        </Column>
        <Column
          field="user.department"
          header="Departamento"
          sortable
          filterField="user.department"
          :exportable="false"
          v-if="
            loggedUser.isEsa ||
            loggedUser.department == 'Administradores' ||
            loggedUser.id == 128
          "
        >
          <template #body="slotProps">
            {{ slotProps.data.user.department }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="trainingAction.name"
          header="Formação"
          sortable
          filterField="trainingAction.name"
        >
          <template #body="slotProps">
            {{ slotProps.data.trainingAction.name }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="trainingAction.year"
          header="Ano"
          sortable
          filterField="trainingAction.year"
          :exportable="false"
        >
          <template #body="slotProps">
            {{ slotProps.data.trainingAction.year }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="trainingAction.duration"
          header="Duração"
          sortable
          filterField="trainingAction.duration"
        >
          <template #body="slotProps">
            {{ slotProps.data.trainingAction.duration }} Horas
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column field="day" header="Data" sortable filterField="day">
          <template #body="slotProps">
            {{ slotProps.data.day }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="start"
          header="Inicio"
          sortable
          filterField="start"
          :exportable="false"
        >
          <template #body="slotProps">
            {{ slotProps.data.start }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="end"
          header="Fim"
          sortable
          filterField="end"
          :exportable="false"
        >
          <template #body="slotProps">
            {{ slotProps.data.end }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="duration"
          header="Realizado"
          sortable
          filterField="duration"
        >
          Horas
          <template #body="slotProps">
            {{ numToTime(slotProps.data.duration) }} Horas /
            {{
              getPercentage(
                slotProps.data.duration,
                slotProps.data.trainingAction.duration
              )
            }}%
          </template>
        </Column>
        <Column>
          <template #body="slotProps">
            <Button
              icon="pi pi-pencil"
              class="p-button-rounded p-button-success p-button-outlined p-mr-2"
              @click="edit(slotProps.data)"
            />
          </template>
        </Column>
        <template #groupfooter="slotProps">
          <td>
            <b> Total Horas de Formação </b>
          </td>

          <td style="width: 40%">{{ footerValues[slotProps.data.user_id] }}</td>
        </template>
      </DataTable>
    </div>
    <Dialog
      :visible.sync="showForm"
      :style="{ width: $isMobile() ? '100vw' : '80vw', position: 'relative' }"
      :header="
        participation.id == undefined
          ? 'Criar Participação em Formação'
          : 'Atualizar Participação em Formação'
      "
      :modal="true"
      :contentStyle="{ overflow: 'hide' }"
      @hide="closeForm"
    >
      <form
        name="participationForm"
        class="p-col-12"
        @submit.prevent="saveParticipation"
        style="min-height: 50vh"
      >
        <div class="p-fluid p-formgrid p-grid">
          <div
            class="p-field p-col-12 p-md-4 p-mt-4"
            v-if="
              loggedUser.isEsa ||
              loggedUser.department == 'Administradores' ||
              loggedUser.id == 128
            "
          >
            <span class="p-float-label">
              <Dropdown
                v-model="participation.user_id"
                name="training"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-error': errors.has('training') },
                  'form-control',
                ]"
                :options="activeUsers"
                :optionLabel="'username'"
                :optionValue="'id'"
                :dataKey="'id'"
                :filter="true"
                :required="true"
              >
              </Dropdown>
              <label for="inputtext">Colaborador</label>
            </span>
            <small v-if="errors.has('training')" class="p-error" role="alert"
              >Colaborador é obrigatório</small
            >
          </div>
          <div class="p-field p-col-12 p-md-4 p-mt-4">
            <span class="p-float-label">
              <Dropdown
                v-model="participation.training_action_id"
                name="training"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-error': errors.has('training') },
                  'form-control',
                ]"
                :options="userTrainings"
                :optionLabel="'name'"
                :optionValue="'id'"
                :dataKey="'id'"
                :filter="true"
                :required="true"
              >
              </Dropdown>
              <label for="inputtext">Formação</label>
            </span>
            <small v-if="errors.has('training')" class="p-error" role="alert"
              >Formação é obrigatório</small
            >
          </div>

          <div class="p-field p-col-12 p-md-2 p-mt-4">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-calendar"></i>
              </span>
              <span class="p-float-label">
                <v-date-picker
                  :name="'day'"
                  :id="'day'"
                  v-model="participation.day"
                  v-validate="'required'"
                  style="width: 100%"
                  :max-date="new Date()"
                  :masks="{
                    input: 'YYYY-MM-DD',
                  }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <span class="p-float-label">
                      <input
                        v-bind:class="[
                          {
                            'p-error': errors.has('day'),
                          },
                          'form-control',
                          'p-inputtext',
                          'p-component',
                          'p-filled',
                        ]"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                      <label for="inputtext">Dia</label>
                    </span>
                  </template>
                </v-date-picker>
              </span>
            </div>
            <small v-if="errors.has('day')" class="p-error" role="alert"
              >Dia é obrigatório</small
            >
          </div>
          <div class="p-field p-col-12 p-md-2 p-mt-1">
            <span class="p-float-label">
              <label for="inputtext">Hora de início</label>
            </span>
            <v-date-picker
              name="start"
              mode="time"
              class="p-mt-2 width-100"
              v-model="participation.start"
              v-validate="'required|date_format:H:mm'"
              is24hr
            />
            <small v-if="errors.has('start')" class="p-error" role="alert"
              >Hora de início da formação é obrigatório</small
            >
          </div>
          <div class="p-field p-col-12 p-md-2 p-mt-1">
            <span class="p-float-label">
              <label for="inputtext">Hora de fim</label>
            </span>
            <v-date-picker
              name="end"
              mode="time"
              class="p-mt-2 width-100"
              v-model="participation.end"
              v-validate="'required|date_format:H:mm|after_start'"
              is24hr
            />
            <small v-if="errors.has('end')" class="p-error" role="alert"
              >Hora de fim da formação é obrigatório e posterior à de
              inicio</small
            >
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-text p-button-success"
          @click="saveParticipation"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-text p-button-danger"
          @click="closeForm"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import { FilterMatchMode } from "primevue/api/";
import { getOnlyDate, getOnlyTime } from "../helpers/helpers";
import trainingService from "../services/training.service";
import employeeService from "../services/employee.service";
import VeeValidate from "vee-validate";
export default {
  name: "TrainingParticipation",
  data() {
    return {
      loading: true,
      participationList: [],
      userTrainings: [],
      activeUsers: [],
      filters: {
        "user.username": { value: null, matchMode: FilterMatchMode.EQUALS },
        "user.department": { value: null, matchMode: FilterMatchMode.CONTAINS },
        "trainingAction.name": {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        "trainingAction.duration": {
          value: null,
          matchMode: FilterMatchMode.EQUALS,
        },
        "trainingAction.year": {
          value: null,
          matchMode: FilterMatchMode.EQUALS,
        },
        day: { value: null, matchMode: FilterMatchMode.CONTAINS },
        start: { value: null, matchMode: FilterMatchMode.CONTAINS },
        end: { value: null, matchMode: FilterMatchMode.CONTAINS },
        duration: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      showForm: false,
      participation: {},
      employeesSelected: null,
      footerValues: [],
      year: new Date().getFullYear(),
      currentYear: new Date().getFullYear(),
    };
  },
  computed: {
    loggedUser() {
      return this.$store.state.auth.user;
    },
  },
  async created() {
    await this.getUserParticipation();
    await this.getUserTrainings();
    if (
      this.loggedUser.isEsa ||
      this.loggedUser.department == "Administradores" ||
      this.loggedUser.id == 128
    ) {
      await this.getActiveUsers();
    }
    this.loading = false;

    VeeValidate.Validator.extend("after_start", {
      validate: () =>
        new Promise((resolve) => {
          resolve({
            valid: this.participation.end > this.participation.start,
          });
        }),
    });
  },
  methods: {
    getActiveUsers() {
      return employeeService
        .getList()
        .then((response) => (this.activeUsers = response));
    },
    getUserParticipation() {
      return trainingService.userParticipations(this.year).then((response) => {
        return (this.participationList = response);
      });
    },
    getUserTrainings() {
      return trainingService
        .userTrainings(this.loggedUser.id)
        .then((response) => {
          return (this.userTrainings = response);
        });
    },
    numToTime(number) {
      var hour = Math.floor(number);
      var decpart = number - hour;
      var min = 1 / 60;
      decpart = min * Math.round(decpart / min);
      var minute = Math.floor(decpart * 60) + "";
      if (minute.length < 2) {
        minute = "0" + minute;
      }
      let time = hour + ":" + minute;

      return time;
    },
    getPercentage(partial, total) {
      let percentage = Math.round((partial / total) * 100).toFixed(2);
      return new Intl.NumberFormat("pt-PT").format(percentage);
    },
    closeForm() {
      this.showForm = false;
      this.$validator.pause();
      this.$validator.reset();
      if (this.participation.id != undefined) {
        this.participation.day = getOnlyDate(this.participation.day);
        this.participation.start = getOnlyTime(this.participation.start);
        this.participation.end = getOnlyTime(this.participation.end);
      }
      this.participation = {};
    },
    newTraining() {
      this.participation = {};
      this.participation["user_id"] = this.loggedUser.id;
      this.showForm = true;
    },
    edit(participation) {
      this.participation = participation;
      this.participation.start = new Date(
        `${this.participation.day} ${this.participation.start}`
      );
      this.participation.end = new Date(
        `${this.participation.day} ${this.participation.end}`
      );
      this.participation.day = new Date(this.participation.day);
      this.showForm = true;
    },
    saveParticipation() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          return;
        }
        this.$validator.pause();
        this.$validator.reset();
        if (this.participation.id == undefined) {
          return this.saveNewParticipation();
        } else {
          return this.updateParticipation();
        }
      });
    },
    saveNewParticipation() {
      let bodyParms = {
        user_id: this.participation.user_id,
        training_action_id: this.participation.training_action_id,
        date: getOnlyDate(this.participation.day),
        start: getOnlyTime(this.participation.start),
        end: getOnlyTime(this.participation.end),
      };
      return trainingService.createParticipation(bodyParms).then((response) => {
        if (!response) {
          return this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: "Ocorreu um erro ao criar a participação na formação",
            life: 3000,
          });
        }
        this.participationList.push(response);
        this.$toast.add({
          severity: "success",
          summary: "Participação na Formação Criada",
          detail: "A participação na formação foi criada com sucesso",
          life: 3000,
        });

        return this.closeForm();
      });
    },
    updateParticipation() {
      let bodyParms = {
        user_id: this.participation.user_id,
        training_action_id: this.participation.training_action_id,
        date: getOnlyDate(this.participation.day),
        start: getOnlyTime(this.participation.start),
        end: getOnlyTime(this.participation.end),
      };
      return trainingService
        .updateParticipation(this.participation.id, bodyParms)
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao criar a participação na formação",
              life: 3000,
            });
          }
          this.$toast.add({
            severity: "success",
            summary: "Participação na Formação Criada",
            detail: "A participação na formação foi criada com sucesso",
            life: 3000,
          });
          return this.closeForm();
        });
    },
    calculateTotalTraining(event) {
      let dataInGrid = event.filteredValue;
      let userInGrid = [...new Set(dataInGrid.map((data) => data.user_id))];
      userInGrid.forEach((userId) => {
        let participations = dataInGrid.filter(
          (part) => part.user_id == userId
        );
        const total = participations.reduce(
          (prev, next) => prev + next.duration,
          0
        );
        this.footerValues[userId] = this.numToTime(total);
      });
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    async yearChanged(year) {
      if (year == this.year) {
        return;
      }
      this.loading = true;
      this.year = year;
      await this.getUserParticipation();
      await this.getUserTrainings();
      return (this.loading = false);
    },
  },
};
</script>
<style>
.vc-date {
  display: none !important;
}
.width-100 {
  width: 100%;
}
.p-rowgroup-header {
  background-color: #4d505b !important;
  color: #ffffff !important;
}
</style>
